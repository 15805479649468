import React, {Component} from 'react'
import NotificationsMenu from '../../notificationsMenu/App'
import Search from '../../search/App'
import OnboardingProgress from './OnboardingProgress'

class NavDesktop extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {logo, areas} = this.props.railsProps

    const {climber, is_loggedin, gradingSystem} = this.props

    const climberPhotoUrl = climber.photo_url
    const climberUrl = '/climbers/' + climber.param_id

    const subscription = is_loggedin ? climber.subscription : null

    return (
      <div className="nav-desktop">
        <div className="navbar-header navbar-header--desktop">
          <a href="/" className="logo">
            &nbsp;
          </a>
          <Search areas={areas} gradingSystem={gradingSystem}>
            <i className="glyphicon glyphicon-search"/>
            <span className="navbar-search-button__label">Search Areas, Crags, Routes etc.</span>
          </Search>
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="/crags">Browse map</a>
            </li>
            <li>
              <a href="/create_topo">Create topos</a>
            </li>
            <li>
              <a href="/shop">Shop</a>
            </li>
            {is_loggedin && (
              <li>
                <a href="/feed">Feed</a>
              </li>
            )}
            {!subscription && (
              <li className="get-premium">
                <a href="/premium">Get Premium</a>
              </li>
            )}
            {!is_loggedin && (
              <li>
                <a href="/login">Sign in</a>
              </li>
            )}
            {is_loggedin && (
              <li>
                <NotificationsMenu
                  climberId={climber.id}
                  climberParamid={climber.param_id}
                  unopenedCount={climber.notifications_unopened_count}
                />
              </li>
            )}
            {is_loggedin && (
              <li className="dropdown user-menu">
                {climber.onboardingProgressVisible && (
                  <div className="user-menu-notifications hidden-xs">{4 - climber.onboarding_state}</div>
                )}
                <a
                  className="dropdown dropdown-toggle nav-item"
                  aria-expanded="false"
                  aria-haspopup="true"
                  data-toggle="dropdown"
                  role="button"
                  id="userDropdownMenu"
                >
                  <div className="nav-item-container">
                    <div className="nav-item-icon visible-xs-inline-block">
                      <i className="glyphicon glyphicon-user"/>
                    </div>
                    <div
                      className="nav-item-label visible-xs-inline-block">{`${climber.first_name} ${climber.last_name}`}</div>
                    <img className="user-menu-image img-circle hidden-xs" src={climberPhotoUrl} alt="climber"/>
                    <span className="caret"/>
                  </div>
                </a>
                <ul className="dropdown-menu" role="menu" aria-labelledby="userDropdownMenu">
                  <li>
                    <a href={climberUrl}>My profile</a>
                  </li>
                  <li>
                    <a href={`${climberUrl}/ascents`}>Ascents</a>
                  </li>
                  {climber.onboardingProgressVisible && <li className="divider hidden-xs"/>}
                  {climber.onboardingProgressVisible && (
                    <li className="onboarding-container">
                      <OnboardingProgress onboarding_state={climber.onboarding_state}/>
                    </li>
                  )}
                  <li className="divider hidden-xs"/>
                  <li>
                    <a href={`${climberUrl}/settings`}>Settings</a>
                  </li>
                  <li>
                    <a href="/logout">Logout</a>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </div>
      </div>
    )
  }
}

export default NavDesktop
