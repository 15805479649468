import React, {Component} from 'react'
import NotificationsMenu from '../../notificationsMenu/App'
import Search from '../../search/App'
import OnboardingProgress from './OnboardingProgress'

class NavMobile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navbarOpen: false,
    }
  }

  onNavbarToggeClick() {
    this.setState({navbarOpen: !this.state.navbarOpen})
  }

  render() {
    const {logo, areas} = this.props.railsProps

    const {climber, is_loggedin, gradingSystem} = this.props

    const climberUrl = climber.climber_url
    const climberPhotoUrl = climber.photo_url

    const subscription = is_loggedin ? climber.subscription : null

    return (
      <div className="nav-mobile">
        <div className="navbar-header navbar-header--mobile">
          <a href="/" className="logo">
            &nbsp;
          </a>
          <button className="navbar-toggle collapsed" type="button" onClick={this.onNavbarToggeClick.bind(this)}>
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"/>
            <span className="icon-bar"/>
            <span className="icon-bar"/>
          </button>
          {is_loggedin && (
            <div className="mobile-header-item">
              <NotificationsMenu
                climberId={climber.id}
                climberParamid={climber.param_id}
                unopenedCount={climber.notifications_unopened_count}
              />
            </div>
          )}
          <div className="mobile-header-item mobile-header-item--search">
            <Search areas={areas} gradingSystem={gradingSystem}>
              <i className="glyphicon glyphicon-search"/>
            </Search>
          </div>
        </div>
        <div className={`collapse navbar-collapse main-menu collapse--mobile ${this.state.navbarOpen ? 'in' : ''} `}>
          <ul className="nav navbar-nav">
            <li>
              <a href="/crags">Browse map</a>
            </li>
            <li>
              <a href="/create_topo">Create topos</a>
            </li>
            {!subscription && (
              <li>
                <a href="/premium">Get Premium</a>
              </li>
            )}
            <li>
              <a href="/site/shop">Shop</a>
            </li>
            {is_loggedin ? (
              <li>
                <a href="/feed">Feed</a>
              </li>
            ) : (
              <li>
                <a href="/login">Sign in</a>
              </li>
            )}
          </ul>

          {is_loggedin && (
            <ul className="nav navbar-nav user-menu user-menu--mobile">
              <li className="user-menu__user">
                <a href={`${climberUrl}`}>
                  <img className="user-menu-image img-circle" src={climberPhotoUrl} alt="climber"/>
                  {climber.name}
                </a>
              </li>
              {climber.onboardingProgressVisible && (
                <li className="onboarding-container">
                  <OnboardingProgress onboarding_state={climber.onboarding_state}/>
                </li>
              )}
              <li>
                <a href={`${climberUrl}/ascents`}>Ascents</a>
              </li>
              <li>
                <a href={`${climberUrl}/notifications`}>Notifications</a>
              </li>
              <li>
                <a href={`${climberUrl}/settings`}>Settings</a>
              </li>
              <li>
                <a href="/logout">Logout</a>
              </li>
            </ul>
          )}
        </div>
      </div>
    )
  }
}

export default NavMobile
